import Config from './config.js';
import TokenStore from './token_store.js';

export default class LoginController {
  constructor() {
    this.url = new URL(document.URL);
    this.searchParams = new URLSearchParams(window.location.search);
    this.config = new Config(this.url.hostname);
    this.store = new TokenStore('slideshow');
  }

  siteNameFromURL() {
    return this.searchParams.get('site-name');
  }

  run() {
    const loginForm = new LoginForm();
    try {
      this.store.initialize();
    } catch (e) {
      if (e instanceof DOMException) {
        console.log(`DOMException: ${e.message}`);
        loginForm.showErrors(['Error: You must enable cookies to use this site.']);
        return;
      }
      throw e;
    }

    loginForm.setDefaultSiteNames(
      this.siteNameFromURL(),
      this.store.retrieve('site-name'));
    loginForm.submitButton.addEventListener("click", (e) => {
      this.login(loginForm.siteNameField.value, loginForm.passwordField.value)
        .then(errs => {
          if (errs.length == 0) {
            this.tryRedirect();
          } else {
            loginForm.showErrors(errs);
          }
        }).catch(err => {
          console.error(err);
          loginForm.showErrors(['Internal error: Please try again later.']);
        });
    });
  }

  customerURL() {
    const fragment = this.store.retrieve('site-url-fragment');
    if (fragment) {
      const u = new URL(document.URL);
      u.pathname = [fragment, 'index.html'].join('/');
      return u.toString();
    }
    return null;
  }

  hasAuthorizedRedirect() {
    const url = this.customerURL();
    const token = this.store.retrieve('api-token');
    if (!(url && token && token.length > 0)) {
      return false;
    }
    // FIXME make a HEAD request before we leave to ensure the customer URL is not bogus?
    return true;
  }

  tryRedirect() {
    if (this.hasAuthorizedRedirect()) {
      window.location.href = this.customerURL();
    }
  }

  urlFragmentFromName(sitename) {
    return sitename.split(/\s/).join('-').toLowerCase();
  }

  async login(siteName, password) {
    console.log(`Attempting login for site ${siteName}`);
    const response = await fetch(`${this.config.APIURL}/session`, {
      method: 'POST',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: `name=${siteName}&password=${password}`
    });
    if (!response.ok) {
      if (response.status == 403) {
        return ['The site name or password was incorrect.'];
      }
      throw new Error(`Unexpected API response ${response.status}`);
    }
    const data = await response.json();
    if (!('token' in data)) {
      throw new Error(`Could not find an API token.`);
    }
    this.store.store('site-name', siteName);
    this.store.store('api-token', data['token']);
    this.store.store('site-url-fragment', this.urlFragmentFromName(siteName));
    return [];
    // FIXME retry periodically if the API seems to be down -- use JS's AbortController to detect this
    // FIXME if we know the site location and the API remains down for a while,
    //   redirect to that location to show the offline slide show,
    //   adding a link on that page back to this login page so the user can eventually try login again.
  }
}

class LoginForm {
  get siteNameField() {
    return document.getElementById("login").querySelector("input#sitename");
  }
  get passwordField() {
    return document.getElementById("login").querySelector("input#sitepass");
  }
  get messagesField() {
    return document.getElementById("messages");
  }
  get submitButton() {
    return document.getElementById("login").querySelector("button[type=submit]");
  }

  setDefaultSiteNames(...names) {
    for (const n of names) {
      if (n) {
        this.siteNameField.value = n;
        return;
      }
    }
  }

  showErrors(messages) {
    while (this.messagesField.firstChild && this.messagesField.firstChild.remove());
    for (const m of messages) {
      const elem = document.createElement('li');
      elem.textContent = m;
      this.messagesField.appendChild(elem);
    }
    this.passwordField.value = "";
  }
}
