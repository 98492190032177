export default class Config {
  constructor(hostname = 'slideshow.storytend.net') {
    this.APIURL = "https://storyserve.storytend.net/slide-api";
    if (hostname == 'localhost') {
      // we are in dev mode
      this.APIURL = "http://localhost:8080/slide-api";
    }
    this.pauseShowOnClick = false; // for debugging
    this.pinRefreshIntervalSec = 60;
    this.slideRefreshIntervalSec = 60;
  }
}
