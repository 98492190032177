export default class TokenStore {
  constructor(prefix = 'slideshow') {
    this.prefix = prefix;
    this.storage = null;
  }

  // Will throw a DOMException if localStorage is disabled.
  initialize() {
    this.storage = window.localStorage;
    return this;
  }

  expandKey(key) {
    return `${this.prefix}-${key}`;
  }

  retrieve(key) {
    if (this.storage) {
      return this.storage.getItem(this.expandKey(key));
    }
    return null;
  }

  store(key, value) {
    if (this.storage) {
      this.storage.setItem(this.expandKey(key), value);
    }
  }

  remove(key) {
    if (this.storage) {
      this.storage.removeItem(this.expandKey(key));
    }
  }
}
