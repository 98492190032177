import api from "!../.yarn/$$virtual/style-loader-virtual-168ea079f7/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-ffc3054882.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../.yarn/$$virtual/css-loader-virtual-c95924ded5/0/cache/css-loader-npm-5.1.1-3ba6cea690-620fae3cac.zip/node_modules/css-loader/dist/cjs.js!./login.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};